import {announceFromElement} from '@github-ui/aria-live'

export function focusOnSearchInputAndAnnounceResults() {
  const input = document.getElementById('project-search-input') as HTMLInputElement
  if (!input) return

  const url = new URL(window.location.href, window.location.origin)
  if (url.searchParams.has('is_search')) {
    url.searchParams.delete('is_search')
    history.replaceState({}, document.title, url.toString())

    input?.focus()
    input?.setSelectionRange(input.value.length, input.value.length)

    // For screen readers, focus takes precedence over ARIA Live Region updates.
    // However, if the ARIA Live Region update is delayed, the ARIA Live Region update will be announced.
    setTimeout(() => {
      const searchResultElement = document.getElementById('projects-search-results-text')
      if (searchResultElement) {
        announceFromElement(searchResultElement)
      }
    }, 1000)
  }
}
